import React, { useState } from "react";
import ImageOffice from "./assets/3.jpeg";

import {
  ContactContainer,
  TextContainer,
  ImageContainer,
  Title,
  Line,
  Text,
  TextContent,
  OfficeImage,
  List,
} from "./styles";

const ThirdInfoContainer = () => {
  return (
    <ContactContainer>
      <TextContainer>
        <TextContent>
          <Title>BEREAVEMENT SERVICES</Title>
          <Line />
          <List>
            <Text>
              Our bereavement services are an essential part of our holistic
              approach to hospice care.
            </Text>
            <Text>
              Services are provided by a team of trained professionals,
              including a licensed social worker and spiritual care counselor.
            </Text>
            <Text>
              We offer individual and group counseling, providing ongoing
              support for up to 13 months following the loss of a loved one.
            </Text>
          </List>
        </TextContent>
      </TextContainer>
      <ImageContainer>
        <OfficeImage src={ImageOffice} alt="holding hands" />
      </ImageContainer>
    </ContactContainer>
  );
};

export default ThirdInfoContainer;
