import React from "react";

import {
  Button,
  ButtonMobile,
  FaxNumber,
  MainContainer,
  SendTo,
  TextButtonContainer,
  TextContainer,
  Title,
  TitleButtonContainer,
  ImageContainer,
  TitleTextContainer,
  ButtonContainer,
  ImgStyles,
  TextContainerMobile,
  FaxNumberMobile,
  ButtonMobileContainer,
} from "./styles";
import DownloadIcon from "../../images/DownloadIcon.svg";
import Referal_Hospice from "../../../static/pdf/Referal_Hospice.pdf";

const ReferPatient = () => {
  return (
    <MainContainer id="referPatient">
      <TextButtonContainer>
        <TitleButtonContainer>
          <ImageContainer>
            <ImgStyles src={DownloadIcon} alt="Download Icon" />
          </ImageContainer>
          <TitleTextContainer>
            <Title>Download the Hospice Order Form here.</Title>
            <TextContainer>
              <SendTo>Send referrals to:</SendTo>
              <FaxNumber>
                Fax: 1-800-404-1380 | Email: kathy@hospicecare.life
              </FaxNumber>
            </TextContainer>
            <TextContainerMobile>
              <FaxNumberMobile>
                Fax: 1-800-404-1380 <br /> Email: kathy@hospicecare.life
              </FaxNumberMobile>
            </TextContainerMobile>
          </TitleTextContainer>
          <ButtonContainer
            href={Referal_Hospice}
            download="Life_Hospice_Referal_Form.pdf"
          >
            <Button>DOWNLOAD FORM</Button>
          </ButtonContainer>
        </TitleButtonContainer>
      </TextButtonContainer>
      <ButtonMobileContainer>
        <a href={Referal_Hospice} download="Life_Hospice_Referal_Form.pdf">
          <ButtonMobile>DOWNLOAD FORM</ButtonMobile>
        </a>
      </ButtonMobileContainer>
    </MainContainer>
  );
};

export default ReferPatient;
