import styled from "styled-components";

import { COLORS } from "../../styles/globals/colors";
import { TABLET_992, TABLET_800, MOBILE_460 } from "../../styles/globals/sizes";

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1500px;
  width:100%;
  margin: auto;
  
  @media (max-width: ${TABLET_800}px) {
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  width: 50%;
  background: #F2F2F2 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0 20px;

  @media (max-width: ${TABLET_800}px) {
    width: 100%;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding: 50px 0 30px;
  }
`;

export const TextContent = styled.div`
  width: 80%;

  @media (max-width: ${TABLET_800}px) {
    width: 90%;
  }
`;

export const ImageContainer = styled.div`
  width: 50%;
  max-height: 403px;;
  @media (max-width: ${TABLET_992}px) {
    max-height: 100%;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    height: 401px;
  }
`;

export const Title = styled.h3`
  font: normal normal normal 37px/49px Roboto;
  color: #F170AE;

  @media (max-width: ${MOBILE_460}px) {
    font: normal normal normal 28px/40px Roboto;
  }
`;

export const Line = styled.div`
  width: 20%;
  border-bottom: 4px solid #F170AE;
  margin: 20px 0;
`;

export const Text = styled.div`
  width: 80%;
  font: 400 18px/26px Roboto;
  color: ${COLORS.gray};
  margin-bottom: 15px;

  @media (max-width: ${TABLET_800}px) {
    margin-bottom: 15px;
    width: 100%;
  }
`;

export const OfficeImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right;
`;
