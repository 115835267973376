import React, { useState } from "react";
import axios from "axios";
import { API_URI } from "../../config";
import { InputForm } from "./input/index";

import BackImage from "./assets/callback.png";

import {
  ContactContainer,
  TextContainer,
  ImageContainer,
  Picture,
  Title,
  Line,
  Text,
  Button,
  Form,
  TextContent,
  BottomSection,
  FormMessage,
  CheckboxWrapper,
  CheckboxText,
  CheckboxInput,
  ErrorMessage,
  ModalDescription,
  CheckboxWrapperLong,
  CheckboxInputLong,
} from "./styles";
import ModalForm from "../ModalForm";

const ContactForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputs, setInputs] = useState({ receiveEmail: false });
  const [succesForm, setSuccesForm] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [showError, setError] = useState(false);

  const handleChange = (event) => {
    setSuccesForm(false);
    setErrorForm(false);
    setError(false);
    const { name } = event.target;
    const { value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.receiveEmail) {
      try {
        await axios.post(`${API_URI}/api/home-care/contactMail/`, inputs);
        setSuccesForm(true);
        setInputs({});
      } catch (error) {
        setErrorForm(true);
      }
    } else {
      setError(true);
      e.preventDefault();
    }
  };

  const changeSetCheck = () => {
    setError(false);
    setInputs((values) => ({ ...values, receiveEmail: !inputs.receiveEmail }));
  };

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const isNumber = keyCode >= 48 && keyCode <= 57;

    if (isNumber) {
      event.preventDefault();
    }
  };
  const handlePrivacyPolicyChange = () => {
    setError(false);
    setInputs((values) => ({
      ...values,
      privacyPolicy: !inputs.privacyPolicy,
    }));
  };

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <ContactContainer id="callBack">
      <TextContainer>
        <TextContent>
          <Title>REQUEST A CALL BACK</Title>
          <Line />
          <Text>We will call you back within 24 hours</Text>
          <Form onSubmit={handleSubmit}>
            <InputForm
              id="name"
              type="text"
              name="name"
              placeholder="Name"
              errorMsg="Invalid name"
              required={true}
              value={inputs.name || ""}
              onChange={handleChange}
            />
            <InputForm
              id="email"
              type="email"
              name="email"
              placeholder="Email"
              errorMsg="Invalid email"
              required={true}
              value={inputs.email || ""}
              onChange={handleChange}
            />
            <InputForm
              id="phone"
              type="phone"
              name="phone"
              placeholder="Phone"
              errorMsg="Invalid phone"
              required={true}
              value={inputs.phone || ""}
              onChange={handleChange}
            />
            <CheckboxWrapper>
              <CheckboxInput
                type="checkbox"
                checked={inputs.receiveEmail}
                onChange={changeSetCheck}
              />
              <CheckboxText>
                I consent to receive information from Life Hospice
              </CheckboxText>
            </CheckboxWrapper>
            <CheckboxWrapperLong>
              <CheckboxInputLong
                type="checkbox"
                onChange={handlePrivacyPolicyChange}
              />
              <CheckboxText>
                I have read and agree to Life Hospice{" "}
                <span
                  onClick={handleModalToggle}
                  style={{
                    color: "white",
                    zIndex: 150,
                    cursor: "pointer",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  SMS privacy policy
                </span>
              </CheckboxText>
            </CheckboxWrapperLong>
            {showError && (
              <ErrorMessage>
                Please consent to receive information before sending request
              </ErrorMessage>
            )}
            <BottomSection>
              <Button type="submit"> Send Request </Button>
              {succesForm ? (
                <FormMessage>Information sent successfully</FormMessage>
              ) : (
                errorForm && (
                  <FormMessage>Error. Please try again later</FormMessage>
                )
              )}
            </BottomSection>
          </Form>
        </TextContent>
      </TextContainer>
      <ModalForm isOpen={isModalOpen} onClose={handleModalToggle}>
        <ModalDescription>
          <span style={{ fontWeight: "bold" }}>Privacy Notice: </span>
          When you opt-in to receive SMS messages from us, we want to assure you
          that we respect your privacy. We do not share or sell your phone
          number or any personal information associated with your opt-in for the
          purposes of SMS or marketing purposes. Your trust is important to us,
          and we use your information solely for the purpose of sending you
          relevant SMS messages as requested. If you have any questions or
          concerns, feel free to contact us at{" "}
          <span style={{ color: "#0000ff", textDecoration: "underline" }}>
            hello@hospicecare.life
          </span>
        </ModalDescription>
      </ModalForm>
      <ImageContainer>
        <Picture>
          <img src={BackImage} alt="group of people" />
        </Picture>
      </ImageContainer>
    </ContactContainer>
  );
};

export default ContactForm;
