import React from "react";
import Logo from './assets/logo-chap.png';
import {
    OurCredentialsContainer,
    Text,
    Image
} from "./styles";

const OurCredentialsSection = () => {
  return (
    <OurCredentialsContainer>
      <Text>Life Hospice is accredited by CHAP - Community Health Accreditation Program</Text>
      <Image src={Logo} />
    </OurCredentialsContainer>
  );
};

export default OurCredentialsSection;
