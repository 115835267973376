import {
  AvailablePhoneContainer,
  AvailableText,
  AvailablePhone,
  CardParagraph,
  CardTitle,
  ContactUsPhone,
  GetInTouchContainer,
  GetInTouchContainerMobile,
  GetInTouchTitle,
  HeroContainer,
  HeroPicture,
  HeroTextDiv,
  Icon,
  InternalWrapper,
  LeftContainer,
  Line,
  LineMobile,
  MobileDiv,
  OurTeamTitle,
  Paragraph,
  PhoneAndParagraph,
  RightContainer,
  Text,
  TextContainer,
  TextContainerOurTeam,
  TextContent,
  TextGetInTouch,
  TextGetInTouchContainer,
  Title,
  TitleService,
  TwoDivsContainer,
  PhoneImage,
} from "../styles/pages/index.jsx";
import { Helmet, HelmetProvider } from "react-helmet-async";
import React, { useEffect } from "react";

import AOS from "aos";
import { CONTACT } from "../config/urls";
import ContactForm from "../components/contactForm";
import Hero1024 from "../images/home-care/Hero.jpeg";
import HeroMobile from "../images/home-care/Hero.jpeg";
import Layout from "../components/layout";
import OfficeStaff from "../components/officeStaff";
import SecondInfoContainer from "../components/secondInfoSection";
import Seoinfo from "../components/seoinfo/seoinfo";
import ThirdInfoContainer from "../components/thirdInfoSection";
import OurCredentialsSection from "../components/OurCredentialsSection";
import mailIcon from "../images/mail-icon.svg";
import phoneIcon from "../images/phone-call.svg";
import pinIcon from "../images/pinIcon.svg";
import seoImage from "../../static/seo/seo_image.png";
import PhoneIcon from "../images/phone-hero.svg";
import ReferPatient from "../components/ReferPatient/index.jsx";

const Home = () => {
  useEffect(
    () => {
      AOS.init({
        //initialise with other settings
        duration: 2000,
        once: true,
      });
    },
    [],
    AOS
  );

  return (
    <HelmetProvider>
      <Layout>
        <Helmet>
          <link rel="canonical" href="https://hospicecare.life/" />
        </Helmet>
        <Seoinfo
          siteName={
            "End-of-Life Care: Providing Comfort and Dignity  | Life Hospice"
          }
          titlePage={
            "End-of-Life Care: Providing Comfort and Dignity  | Life Hospice"
          }
          description={
            "Our mission: Compassionate and High-Quality End-of-Life Care. Meeting Medical, Spiritual, and Emotional Needs with Care and Respect."
          }
          keywords={
            "end-of-life care, comfort and dignity, patient-centered care, compassionate care, quality care"
          }
          type={"website"}
          url={"hospice.life"}
          imageFacebook={seoImage}
        />
        <HeroContainer bgImage={HeroMobile} alt="Woman hugging elder woman">
          <HeroPicture>
            <source srcSet={HeroMobile} type="image/avif" />
            <img src={Hero1024} alt="group of people" />
          </HeroPicture>
          <InternalWrapper>
            <MobileDiv bgImage={HeroMobile} alt="Woman hugging elder woman" />
            <HeroTextDiv>
              <Title>LIFE HOSPICE</Title>
              <PhoneAndParagraph>
                <Paragraph>
                  Promoting Quality of Life, Providing Compassionate Care
                </Paragraph>
                <ContactUsPhone href="#callBack">CONTACT US</ContactUsPhone>
                <ContactUsPhone mobile href="#referPatient">
                  Refer a Patient
                </ContactUsPhone>
                <ContactUsPhone mobile href={`tel:${CONTACT.PHONE}`}>
                  {CONTACT.PHONE_TO_DISPLAY}
                </ContactUsPhone>
                <AvailableText>AVAILABLE 24/7</AvailableText>
                <AvailablePhoneContainer>
                  <PhoneImage src={PhoneIcon} />
                  <AvailablePhone>973-607-4930</AvailablePhone>
                </AvailablePhoneContainer>
              </PhoneAndParagraph>
            </HeroTextDiv>
          </InternalWrapper>
        </HeroContainer>
        <TwoDivsContainer>
          <TextContainer>
            <TextContent>
              <CardTitle>
                OUR MISSION IS TO PROVIDE OPTIMAL COMFORT AND DIGNITY TO OUR
                PATIENTS THROUGH THEIR END-OF-LIFE JOURNEY.
              </CardTitle>
              <CardParagraph>
                With patient and family-centered goals, our team will meet their
                medical, spiritual, and emotional needs with compassionate and
                quality care.
              </CardParagraph>
            </TextContent>
          </TextContainer>
        </TwoDivsContainer>
        <TextContainerOurTeam>
          <TitleService>SERVICES PROVIDED</TitleService>
        </TextContainerOurTeam>
        <OfficeStaff />
        <SecondInfoContainer />
        <ThirdInfoContainer />
        <OurCredentialsSection />
        <GetInTouchContainer>
          <LeftContainer>
            <OurTeamTitle>GET IN TOUCH</OurTeamTitle>
            <Line />
            <Text>
              If you want to know more about the services, please fill the form
              below and a representative will get in touch with you as soon as
              possible.
            </Text>
          </LeftContainer>
          <RightContainer>
            <TextGetInTouchContainer>
              <Icon src={pinIcon} />
              <TextGetInTouch>
                70 South Orange Ave, Suite 245, Livingston, NJ 07039
              </TextGetInTouch>
            </TextGetInTouchContainer>
            <TextGetInTouchContainer>
              <Icon src={phoneIcon} />
              <TextGetInTouch>973-607-4930</TextGetInTouch>
            </TextGetInTouchContainer>
            <TextGetInTouchContainer>
              <Icon src={mailIcon} />
              <TextGetInTouch>hello@hospicecare.life</TextGetInTouch>
            </TextGetInTouchContainer>
          </RightContainer>
        </GetInTouchContainer>
        <GetInTouchContainerMobile>
          <GetInTouchTitle>GET IN TOUCH</GetInTouchTitle>
          <LineMobile />
          <Text>
            If you want to know more about the services, please fill the form
            below and a representative will get in touch with you as soon as
            possible.
          </Text>
          <TextGetInTouchContainer>
            <Icon src={pinIcon} />
            <TextGetInTouch>
              70 South Orange Ave, Suite 245, Livingston, NJ 07039
            </TextGetInTouch>
          </TextGetInTouchContainer>
          <TextGetInTouchContainer>
            <Icon src={phoneIcon} />
            <TextGetInTouch>973-607-4930</TextGetInTouch>
          </TextGetInTouchContainer>
          <TextGetInTouchContainer>
            <Icon src={mailIcon} />
            <TextGetInTouch>hello@hospicecare.life</TextGetInTouch>
          </TextGetInTouchContainer>
        </GetInTouchContainerMobile>
        <ContactForm />
        <ReferPatient />
      </Layout>
    </HelmetProvider>
  );
};

export default Home;
